import Image from "next/image";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "@nextui-org/react";
import { Divider } from "@mui/material";

const Footer = () => {
  return (
    <footer className="footer relative text-gray-200 dark:text-gray-200 bg-black mt-0 md:mt-0">
      {/* <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-4 md:col-span-12">
                  <a href="#" className="text-[22px] focus:outline-none">
                    <Image
                      src="/images/vakily-logo-white.png"
                      alt="Vakily"
                      height={25}
                      width={180}
                      className="object-cover"
                    />
                  </a>
                  <p className="mt-6 text-gray-300">
                    Vakily is India&apos;s cutting-edge cloud SAAS marketplace,
                    providing prompt engineering solutions for the legal sector,
                    Download our apps to using our services
                  </p>
                  <ul className="list-none mt-6">
                    <li className="inline">
                      <a
                        href="https://www.linkedin.com/company/lawinzo/"
                        target="_blank"
                        className="p-2 mx-1  border border-gray-800 rounded-md hover:border-[#FF6350] dark:hover:border-[#FF6350] hover:bg-[#FF6350] dark:hover:bg-[#FF6350]"
                      >
                        <LinkedInIcon className="pb-1" />
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href="https://www.facebook.com/LawinzoERP"
                        target="_blank"
                        className="p-2 mx-1  border border-gray-800 rounded-md hover:border-[#FF6350] dark:hover:border-[#FF6350] hover:bg-[#FF6350] dark:hover:bg-[#FF6350]"
                      >
                        <FacebookIcon className="pb-1" />
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href="/"
                        target="_blank"
                        className="p-2 mx-1 border border-gray-800 rounded-md hover:border-[#FF6350] dark:hover:border-[#FF6350] hover:bg-[#FF6350] dark:hover:bg-[#FF6350]"
                      >
                        <InstagramIcon className="pb-1" />
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href="/"
                        target="_blank"
                        className="p-2 mx-1 border border-gray-800 rounded-md hover:border-[#FF6350] dark:hover:border-[#FF6350] hover:bg-[#FF6350] dark:hover:bg-[#FF6350]"
                      >
                        <TwitterIcon className="pb-1" />
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href="mailto:connect@lawinzo.com"
                        className="p-2 mx-1 border border-gray-800 rounded-md hover:border-[#FF6350] dark:hover:border-[#FF6350] hover:bg-[#FF6350] dark:hover:bg-[#FF6350]"
                      >
                        <MailOutlineIcon className="pb-1" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="lg:col-span-2 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Company
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    <li>
                      <Link
                        href="/producterp.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b"></i> Lawyer ERP
                      </Link>
                    </li>
                    <li className="mt-[10px]">
                      <Link
                        href="/whoweare.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b"></i> Who We Are
                      </Link>
                    </li>
                    <li className="mt-[10px]">
                      <Link
                        href="/services.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b"></i> What We Serve
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Usefull Links
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    <li>
                      <Link
                        href="terms.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b"></i> Terms of
                        Services
                      </Link>
                    </li>
                    <li className="mt-[10px]">
                      <Link
                        href="privacy.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b"></i> Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Contact Us -
                  </h5>
                  <form>
                    <label className="form-label">Vakily - A product of Ozybrains LLP</label>
                    <div className="grid grid-cols-1">
                      <div className="foot-subscribe my-3">
                        <label className="form-label">

                        </label>
                        <label className="form-label">4A, Surya Enclave, Amethiya Nagar, </label>
                        <label className="form-label">
                          Namkum, Ranchi 834010
                        </label>
                      </div>
                    </div>
                  </form>
                  <h5 className="hidden md:grid tracking-[1px] text-gray-100 font-semibold">
                    Download Our Application
                  </h5>
                  <div className="flex justify-content: space-between mt-1">
                    <Link
                      target="_blank"
                      as={Link}
                      href="https://play.google.com/store/apps/details?id=com.lawinzo.android"
                      className="hidden lg:block ml-2 h-[40px] w-[115px]"
                      disableAnimation
                    >
                      <Image
                        className="rounded-md object-cover h-[40px] w-[115px]"
                        src={"/images/playStore1.png"}
                        alt={"Play Store Link"}
                        height={40}
                        width={115}
                      />
                    </Link>
                    <Link
                      target="_blank"
                      as={Link}
                      href="https://apps.apple.com/in/app/lawinzo/id6465453176"
                      className="hidden lg:block ml-2 h-[40px] w-[115px]"
                      disableAnimation
                    >
                      <Image
                        className="rounded-md object-fill h-[40px] w-[115px]"
                        src={"/images/appStoreIcon.png"}
                        alt={"App Store Link"}
                        height={40}
                        width={115}
                      />
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="flex flex-row gap-2 justify-center items-center">
        <ul className="list-none footer-list">
          <li className="inline">
            <Link
              href="/producterp.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> Lawyer ERP
            </Link>
          </li>

          <li className="inline pl-5">
            <Link
              href="/whoweare.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> Who We Are
            </Link>
          </li>
          <li className="inline pl-5">
            <Link
              href="/services.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> What We Serve
            </Link>
          </li>
          <li className="inline pl-5">
            <Link
              href="terms.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> Terms of
              Services
            </Link>
          </li>
          <li className="inline pl-5">
            <Link
              href="privacy.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> Privacy Policy
            </Link>
          </li>
          <li className="inline pl-5">
            <Link
              href="privacy.html"
              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
            >
              <i className="uil uil-angle-right-b"></i> Vakily - A product of Ozybrains LLP, 4A, Surya Enclave, Amethiya Nagar, Namkum, Ranchi 834010
            </Link>
          </li>
        </ul>
      </div> */}
      <div className="py-[10px] lg:text-[16px] text-[10px] px-0 border-t border-white]">
        <div className=" md:container text-center">
          <div className="flex flex-row gap-2 justify-center items-center">
            <ul className="list-none footer-list lg:text-[16px] text-[12px] ml-2">
              {/* <li className="inline">
                <Link
                  href="/producterp.html"
                  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                >
                  <i className="uil uil-angle-right-b"></i> Lawyer ERP
                </Link>
              </li> */}

              <li className="inline lg:text-[16px] text-[12px] ">
                <Link
                  href="/whoweare.html"
                  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out lg:text-[16px] text-[12px]"
                >
                  <i className="uil uil-angle-right-b "></i> Who We Are
                </Link>
              </li>
              {/* <li className="inline pl-5">
                <Link
                  href="/services.html"
                  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                >
                  <i className="uil uil-angle-right-b"></i> What We Serve
                </Link>
              </li> */}
              <li className="inline pl-5 lg:text-[16px] text-[12px]">
                <Link
                  href="terms.html"
                  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out lg:text-[16px] text-[12px]"
                >
                  <i className="uil uil-angle-right-b"></i> Terms of
                  Services
                </Link>
              </li>
              <li className="inline pl-5 lg:text-[16px] text-[12px]">
                <Link
                  href="privacy.html"
                  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out lg:text-[16px] text-[12px]"
                >
                  <i className="uil uil-angle-right-b"></i> Privacy Policy
                </Link>
              </li>
              <li className="inline pl-5 mt-2 md:mt-0 lg:text-[16px] text-[10px]">
                <p
                  className="text-gray-300 p-[10px] md:p-0 lg:text-[16px] text-[10px]"
                >
                  <i className="uil uil-angle-right-b"></i> Vakily - Product of Vakily Softwares Pvt Ltd, 704, 7th floor, Palm Court, MG Road, Sec-16, Gurugram, Haryana, 122007
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
