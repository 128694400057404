import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = "https://api.vakily.app";
const apiHeader = "449772DE-2780-4412-B9F7-E49E48605875";

export const generateOtp = async (mobile) => {
  try {
    const obj = {
      username: mobile,
      alternate: "true",
    };
    // console.log(obj);
    // console.log(apiHeader);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');
    headers.append("x-api-key", apiHeader);
    // console.log(apiUrl);
    const response = await fetch(apiUrl + "/api/auth/signinByMobile", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    // console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      console.log("OTP sent");
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};

export const validateOtp = async (mobile, otp) => {
  try {
    const obj = {
      username: mobile,
      password: otp,
      alternate: "true",
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    const response = await fetch(apiUrl + "/api/auth/verify", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      const data = await response.json();
      //   console.log(data);
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};

export const getUserProfile = async (accessToken) => {
  // console.log(accessToken)
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/user/me", {
      method: "GET",
      headers,
      // body: JSON.stringify(obj),
    });

    // console.log(response);
    if (response.status === 200) {
      const data = await response.json();
      // console.log("get user profile", data);
      return { data: data?.data,status:response.status };
    }
  } catch (e) {
    console.log(e);
  }
};

export const getPeopleByNumber = async (mobileNo ) => {
  try {
    const accessToken = Cookies.get("accessToken"); 
    const headers = {
      "x-api-key": apiHeader,
      "Authorization": `Bearer ${accessToken}`
    };
    const url = apiUrl + `/api/v1/people/search/${mobileNo}`;
    // const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(url, {headers});
    if (response.status === 200) {
      console.log("mobile number", response);
      return response?.data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};
export const searchLawyer = async (phone) => {
  try {
    const accessToken = Cookies.get("accessToken");
    console.log(accessToken)
    if (!accessToken) {
      throw Error("No accessToken or body Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(`${apiUrl}/lawyer/search/${phone}`, {
      headers,
    });
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};
export const UpdateUserProfile = async (
  mobile,
  name,
  email,
  role,
  accessToken
) => {
  try {
    const obj = {
      phone: mobile,
      fullname: name,
      email: email,
      password: mobile,
      alternate: true,
      roles: [role],
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');

    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/user/profile", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};
