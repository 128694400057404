import React, { use, useEffect, useState } from "react";
import {
  Navbar as NextUINavbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Select,
  SelectItem,
  Divider,
  Avatar,
  DropdownSection,
  User,
} from "@nextui-org/react";
import NextLink from "next/link";
import Image from "next/image";
import { getServices } from "../../Apis/Services";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { getUserProfile } from "../../Apis/login";
import { getBareActsList } from "../../Apis/BareActs";

// import ServicesDropdown from "./ServicesDropdown";
const navbarLinkClass =
  "text-[14px] text-white hover:text-[#FF6350] font-[500] pb-2 tracking-widest leading-5";
const activeNavbarLinkClass =
  "text-[14px] hover:text-white text-[#FF6350] border-b-2 border-white pb-2 font-[500] tracking-widest leading-5";

function bareActsLink(link) {
  let newLink = `/bareacts/${link?.title?.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()}-${link?.sno}`
  return newLink
}
export default function Navbar() {
  const NEXT_PUBLIC_S3LINK = process.env.NEXT_PUBLIC_S3LINK
  console.log(NEXT_PUBLIC_S3LINK, process.env)
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [services, setServices] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [serviceName, setServiceName] = useState("SERVICES");
  const [details, setDetails] = useState(null);
  const [value, setValue] = React.useState(new Set(["English"]));
  const [bareAct, setBareAct] = useState([])
  const accessToken = Cookies.get("accessToken");

  const logOut = () => {
    Cookies.remove("accessToken");
    Cookies.remove("assignedRole");
    Cookies.remove("isLoggedIn");
    window.open("/home", "_self");
  };
  // console.log(details?.data);
  //  console.log(allCookies)
  const handleSelectionChange = (e) => {
    // console.log(value);
    setValue(new Set([e.target.value]));
    Cookies.set("language", e.target.value);
    router.reload();
  };
  // useEffect(()=>{
  //   setSearchQuery("")
  // },[router])
  const fetchData = async (value) => {
    const res = await getBareActsList(0, 1041)
    if (res) {
      // console.log(res)
      const result = res?.content?.filter((searchData) => {
        return (
          value && searchData && searchData.title && searchData.title.toLowerCase().includes(value)
        )
      })
      // console.log(result)
      setBareAct(result)
    }
  }
  useEffect(() => {
    console.log(NEXT_PUBLIC_S3LINK, process.env)
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile(accessToken); // Make the API call to getUserProfile
        setDetails(userProfile);
        // console.log("User profile data fetched:", userProfile);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);
  useEffect(() => {
    const langauge = Cookies.get("language");
    if (langauge) {
      setValue(new Set([Cookies.get("language")]));
    }
  }, []);
  // const getAllService = async () => {
  //   const response = await getServices();
  //   if (response) {
  //     // console.log(response);
  //     setServices(response);
  //   }
  // };
  // useEffect(() => {
  //   getAllService();
  //   const { enquiry } = router.query;
  //   if (enquiry) {
  //     setServiceName(enquiry);
  //   }

  // }, [])
  const handleChange = (value) => {
    // console.log(value)
    setSearchQuery(value)
    if (value.length >= 3) {
      // setOpenCase(true)
      fetchData(value)
    }
  }
  const handleNav = (values) => {
    setSearchQuery('')
    // console.log(values)
    if (values) {
      const link = bareActsLink(values)
      router.push(link)
    }
  }
  const pathname = router.asPath || ""
  console.log(pathname)
  return (
    <>
      <NextUINavbar
        isBlurred={false}
        onMenuOpenChange={setIsMenuOpen}
        className="h-[4.7rem] xxl:px-[80px] bg-[#111111] border-b-2 border-white [&>header]:p-0 [&>header]:gap-0 sm:[&>header]:gap-4 sm:[&>header]:px-6"
        maxWidth="xl"
      >
        <NavbarContent justify="start" className="ps-4 sm:ps-3">
          {pathname !== '/client/enquiry' && <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="hidden"
          />}
          <NavbarBrand className="pl-[10px]">
            <Link
              as={NextLink}
              color="foreground"
              href="/"
            >
              <img
                  src='/images/oncloud-logo-white.png'
                alt={"oncloud-logo"}
                height={60}
                width={60}

                className="min-w-[60px] hidden md:flex"
              />
            </Link>

            <img
              src='/images/oncloud-logo-white.png'
              alt={"oncloud-logo"}
              height={60}
              width={60}
              className="min-w-[60px] flex md:hidden"
            />
          </NavbarBrand>
          {/* {pathname !== '/client/enquiry' &&
            <div className="hidden md:flex">
              <ServicesDropdown services={services} />
            </div>
          } */}

        </NavbarContent>

        {/* {pathname !== '/client/enquiry' && <NavbarContent className="w-full hidden lg:flex gap-6" justify="center">
          <Autocomplete
            id="asynchronous-searchByNumber"
            open={open}
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (open) setOpen(false);
              } else {
                if (!open) setOpen(true);
              }
            }}
            onClose={() => setOpen(false)}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                paddingTop: "0px",
                paddingRight: "10px !important",
              },
              width: "90%",
              marginTop: "2px",
            }}
            loadingText="Loading..."
            noOptionsText=""

            onChange={(event, value) => {
           
              handleNav(value)
         
            }}
            value={searchQuery}
            isOptionEqualToValue={(option, value) =>
              option?.title === value
            }
            getOptionLabel={(option) => option?.title}
            options={bareAct}
          
            placeholder="Search"
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ width: "100%" }}>
                {option?.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
              sx={{backgroundColor:'white',borderRadius:2}}
                fullWidth
                {...params}
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  handleChange(event.target.value);
                }}
                name="phoneNumber"
              />
            )}
          />
        </NavbarContent>} */}
        {/* <NavbarContent
          justify="end"
          className="align-center gap-0 sm:gap-1 me-2 sm:me-0 justify-end"
        >
          {pathname !== '/client/enquiry' && <NavbarItem className="flex gap-2 md:hidden">
            <ServicesDropdown services={services} isMobile={true} />
            {!details?.data && (
              <Button
                as={Link}
                className="bg-[#FF6350] hover:bg-[#ffd874] min-w-0 md:min-w-10 text-white"
                href="https://cloud.lawinzo.com"
                target="_blank"
                variant="flat"
                radius="sm"
              >
                <span className="hidden md:block">Lawyer Login</span>
                <PersonOutlineIcon className="md:hidden" />
              </Button>
            )}
          </NavbarItem>}
          {!details?.data && (
            <NavbarItem className="hidden md:block">
              <Button
                as={Link}
                className="bg-[#FF6350] hover:bg-[#ffd874]] min-w-0 md:min-w-10 text-white"
                href="https://cloud.lawinzo.com"
                target="_blank"
                variant="flat"
                radius="sm"
              >
                Lawyer Login
              </Button>
            </NavbarItem>
          )}
          {details?.data && (
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Avatar
                  
                  as="button"
                  className="border-3 rounded-full border-[#FF6350]"
                  color="warning"
                  name={details?.data?.fullname}
                  size="md"
                  src={details?.data?.userProfileImageLink}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                <DropdownSection aria-label="Profile & Actions" showDivider>
                  <DropdownItem
                    isReadOnly
                    key="profile"
                    className="h-14 gap-2 opacity-100"
                  >
                    <User
                      name={details?.data?.fullname}
                      description={details?.data?.phone}
                      classNames={{
                        name: "text-default-600",
                        description: "text-default-500",
                      }}
                      avatarProps={{
                        size: "lg",
                        src: details?.data?.userProfileImageLink,
                      }}
                    />
                  </DropdownItem>
                </DropdownSection>

                <DropdownItem key="dashboard">
                  <a
                    href="https://cloud.lawinzo.com/lawyers/dashboards"
                    target="_blank"
                  >
                    {" "}
                    Dashboard
                  </a>
                </DropdownItem>
                <DropdownItem key="logout" color="warning">
                  <p onClick={() => logOut()}>Log Out</p>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {pathname!=='/client/enquiry' &&     <div className="flex flex-row">
          <NavbarItem>
            <Link
              target="_blank"
              as={Link}
              href="https://play.google.com/store/apps/details?id=com.lawinzo.android"
              className="hidden lg:block ml-2 h-[40px] w-[115px]"
              disableAnimation
            >
              <Image
                className="rounded-md object-cover h-[40px] w-[115px]"
                src={"/images/playStore1.png"}
                alt={"Play Store Link"}
                height={40}
                width={115}
              />
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              target="_blank"
              as={Link}
              href="https://apps.apple.com/in/app/lawinzo/id6465453176"
              className="hidden lg:block ml-2 h-[40px] w-[115px]"
              disableAnimation
            >
              <Image
                className="rounded-md object-fill h-[40px] w-[115px]"
                src={"/images/appStoreIcon.png"}
                alt={"App Store Link"}
                height={40}
                width={115}
              />
            </Link>
          </NavbarItem>
          </div>}
         
        </NavbarContent> */}
        <br />
        {/* side bar menu mobile */}
        {/* {pathname !== '/client/enquiry' && <NavbarMenu className="__variable_598ead font-sans z-50"> */}
        {/* <NavbarMenuItem>
            <Autocomplete
              id="asynchronous-searchByNumber"
              open={open}
              onInputChange={(_, value) => {
                if (value.length === 0) {
                  if (open) setOpen(false);
                } else {
                  if (!open) setOpen(true);
                }
              }}
              onClose={() => setOpen(false)}
              // sx={{ width: "100%"}}
              // open={openCase}
              sx={{
                "& .MuiInputBase-root": {
                  height: "40px",
                  paddingTop: "0px",
                  paddingRight: "10px !important",
                },
                width: "90%",
                marginTop: "2px",
              }}
              // loading={isLoading}
              loadingText="Loading..."
              noOptionsText=""

              onChange={(event, value) => {
                // setSelectedPeople((prev)=>[...prev,value[0]]);
                handleNav(value)
                // console.log("Value.....", value);
              }}
              value={searchQuery}
              isOptionEqualToValue={(option, value) =>
                option?.title === value
              }
              getOptionLabel={(option) => option?.title}
              options={bareAct}
              // multiple
              placeholder="Search"
              renderOption={(props, option, { selected }) => (
                <li {...props} sx={{ width: "100%" }}>
                  {option?.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    handleChange(event.target.value);
                  }}
                  name="phoneNumber"
                />
              )}
            />
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/home"
            >
              Home
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              href="/producterp.html"
              aria-current="page"
            >
              Lawyers ERP
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/services.html"
            >
              What We Serve
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/whoweare.html"
            >
              Who We Are
            </Link>
          </NavbarMenuItem> */}
        {/* <Divider className="my-4" /> */}
        {/* 
          <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/judgement"
            >
              Judgements
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/bareacts"
            >
              Bare Acts
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/bidding"
            >
              Bidding
            </Link>
          </NavbarMenuItem> */}
        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/blog"
            >
              Blogs
            </Link>
          </NavbarMenuItem> */}


        {/* <NavbarMenuItem>
            <Link
              as={NextLink}
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/article"
            >
              Articles
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              className="text-[13px] hover:text-[#FF6350]  text-white font-[500] tracking-widest leading-5"
              color="foreground"
              href="/announcement"
            >
              Announcements
            </Link>
          </NavbarMenuItem> */}
        {/* <Divider className="my-4" /> */}
        {/* <NavbarMenuItem>
          <Button
            as={Link}
            className="bg-[#05686E] hover:bg-[#000] text-white"
            href="https://cloud.lawinzo.com"
            target="_blank"
            variant="flat"
            radius="sm"
          >
            Lawyer Login
          </Button>
        </NavbarMenuItem> */}
        {/* <NavbarMenuItem className="flex items-center">
            <div className="flex justify-content: space-between mt-1">
              <Link
                target="_blank"
                as={Link}
                href="https://play.google.com/store/apps/details?id=com.lawinzo.android"
                className=" lg:block h-[40px] w-[115px]"
                disableAnimation
              >
                <Image
                  className="rounded-md object-cover h-[40px] w-[115px]"
                  src={"/images/playStore1.png"}
                  alt={"Play Store Link"}
                  height={40}
                  width={115}
                />
              </Link>
              <Link
                target="_blank"
                as={Link}
                href="https://apps.apple.com/in/app/lawinzo/id6465453176"
                className="lg:block ml-2 h-[40px] w-[115px]"
                disableAnimation
              >
                <Image
                  className="rounded-md object-fill h-[40px] w-[115px]"
                  src={"/images/appStoreIcon.png"}
                  alt={"App Store Link"}
                  height={40}
                  width={115}
                />
              </Link>
            </div>
            <Link 
            as={NextLink}
            href="#" className="h-auto" disableAnimation>
              <Image
                className="rounded-md h-[40px]"
                src={"/images/playStore1.png"}
                alt={"Play Store Link"}
                height={40}
                width={150}
              />
            </Link>
            <Link as={NextLink}  target="_blank"
              href="https://apps.apple.com/in/app/lawinzo/id6465453176" className="h-auto" disableAnimation>
              <Image
                className="rounded-md"
                src={"/images/appStoreIcon.png"}
                alt={"Play Store Link"}
                height={10}
                width={150}
              />
            </Link>
          </NavbarMenuItem> */}
        {/* </NavbarMenu>} */}
        {/* sub menu list */}
      </NextUINavbar  >
      {/*    
      <NextUINavbar

        isBlurred={false}
        className="hidden border-b-2 border-white bg-[#111111]  pb-0 h-auto fixed mt-[60px] [&>header]:p-0 [&>header]:gap-0 sm:[&>header]:px-6 xxl:px-[80px]"
        maxWidth="xl"
        isBordered
        height={pathname === '/client/enquiry'?"0rem":"3rem"}
      >
       {pathname !== '/client/enquiry' &&  <NavbarContent
          justify="start"
          className="sticky overflow-y-hidden gap-4 ps-4 top-2 !grow-[2]"
        >
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                (pathname === "/home" || router.pathname === "/") ? activeNavbarLinkClass : navbarLinkClass
              }
              color="foreground"
              href="/home"
            >
              Home
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                pathname.includes("bareacts")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/bareacts"
            >
              Bare Acts
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                pathname.includes("judgement")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/judgement"
            >
              Judgements
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              className={
                pathname.includes("bidding")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/bidding"
            >
              Bidding
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              className={
                pathname.includes("enquiries")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/enquiries"
            >
              My Enquiry
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              className={
                pathname.includes("chats")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/chats"
            >
              My Chats
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                pathname.includes("blog")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/blog"
            >
              Blogs
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                pathname.includes("article")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/article"
            >
              Articles
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              as={NextLink}
              className={
                pathname.includes("announcement")
                  ? activeNavbarLinkClass
                  : navbarLinkClass
              }
              color="foreground"
              href="/announcement"
            >
              Announcements
            </Link>
          </NavbarItem>
        </NavbarContent>}
        {pathname !== '/client/enquiry' &&  <NavbarContent
          justify="end"
          className="md:pe-6 gap-0 !justify-center sm:!justify-end"
        >
          <NavbarItem>
            <Select
              selectedKeys={value}
              size="sm"
              variant="bordered"
              radius="sm"
              onChange={handleSelectionChange}
              labelPlacement="outside"
              className="w-[100px] h-[30px] p-0 m-0 [&>button]:shadow-none shadow-none"
              defaultValue={"english"}
              value={"english"}
            >
              <SelectItem key={"English"} value={"English"}>
                English
              </SelectItem>
              <SelectItem key={"Hindi"} value={"Hindi"}>
                Hindi
              </SelectItem>
            </Select>
          </NavbarItem>
        </NavbarContent>}
     
      </NextUINavbar> */}

    </>
  );
}
