import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import { Box, Button, Divider, InputAdornment, Link, Modal, TextField, Typography } from '@mui/material';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import Footer from '../src/component/footer'
import Navbar from '../src/component/Navbar'
import { useWindowSize } from '@react-hook/window-size';
import SocialButtons from '../src/component/SocialButton';
import { ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import Cookies from 'js-cookie';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Home = () => {
  const router = useRouter();
  const [width, height] = useWindowSize()
  console.log(height)
  const [isMobile, setIsMobile] = React.useState(false)
  const [newHeight, setNewHeight] = React.useState(500)
  React.useEffect(() => {
    if (width < 768) {
      setIsMobile(true)
    }
    setNewHeight(height - 150)
  }, [width, height])
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const startMeeting = () => {
    if (e2ee) {
      router.push(`/rooms/${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {

      router.push(`/rooms/${generateRoomId()}`);
    }
  };
  const [joinCode, setJoinCode] = useState("")

  const joinMeeting = () => {
    if (joinCode) {
      router.push(`/rooms/${joinCode}`)
    }
  }
  const [modelOpen, setModelOpen] = React.useState(false)
  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  const accessToken = Cookies.get("accessToken")
  const AddMeeting = () => {
    startMeeting()
    // if (!accessToken) {
    //   setModelOpen(true)
    // }
    // else {
    //   router.push('/meeting/new')
    // }

  }

  return (<>
    <Navbar />
    <div className='h-dvh lg:h-4/5 flex px-[12px] sm:px-[1rem] lg:px-[45px] xl:px-[4rem] 2xl:px-[10rem] justify-evenly w-full flex-col md:flex-row items-center bg-[#111111] self-center py-5 gap-10 overflow-auto '>
      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className='absolute top-2 mb-5' id="modal-modal-title" variant="h6" component="h2">
            Login with google
          </Typography>
          <div className='absolute top-2 right-2'>
            <button onClick={handleClose}> <CloseIcon /></button>
          </div>
          <div className='mt-5'>
          <SocialButtons />
          </div>
        </Box>
      </Modal>
      <div className='flex flex-col p-5 w-full text-white'>
        <h1 className='text-[20px] lg:text-[40px] leading-1 lg:leading-none'>Secure video conferencing for everyone</h1>
        <h3 className='text-[12px] lg:text-[20px] my-5'>Connect, collaborate and celebrate from anywhere with Google Meet</h3>
        <div className='flex flex-col items-center md:flex-row gap-5 mt-3 lg:mt-[5rem]'>
          <Button sx={{ height: 40, backgroundColor: '#FF6350', '&:hover': { backgroundColor: '#c35445' } }} className='bg-[#FF6350] hover:bg-[#c35445] w-full' onClick={AddMeeting} variant="contained" startIcon={<VideoCallIcon />}>New Metting</Button>
          <TextField  size='small' className='text-white bg-white rounded-md w-full' placeholder='Enter a code' value={joinCode} onChange={(e) => setJoinCode(e.target.value)} sx={{ color: 'white' }} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyboardIcon />
              </InputAdornment>
            ),
          }} id="outlined-basic" />
          <Button disabled={!joinCode} sx={{
            height:40, backgroundColor: '#FF6350', '&:hover': { backgroundColor: '#ffd670' }, "&.Mui-disabled": {
              backgroundColor: "gray",
              color: "#c0c0c0"
            }
          }} className='bg-[#FF6350] mt-1 w-full lg:w-10' onClick={() => joinMeeting()} variant="contained" >join</Button>
        </div>
        <Divider sx={{ marginTop: 3 }} className='bg-white' />
        <p className='text-white mt-5'>
          <a href='' className='text-[#FF6350] cursor-pointer'>Learn more</a> about google meet
        </p>
      </div>
      <div className='w-full items-center flex flex-col'>
        <div className='border cursor-pointer  rounded-lg shadow-sm  bg-[#FF6350] w-[95%] scale-105 transition-all shadow-white'>
          <div className='flex flex-row gap-10 px-2 lg:px-10 py-0'>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>17:15</p>
            </div>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>Checkout Demo Call</p>
            </div>
          </div>
        </div>
        <div className='border cursor-pointer hover:rounded-lg shadow-sm P-5 bg-white w-[95%] transition-all hover:-translate-y-1 hover:scale-110 duration-300 shadow-white '>
          <div className='flex flex-row gap-10 px-2 lg:px-10 py-0'>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>17:15</p>
            </div>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>Checkout Demo Call</p>
            </div>
          </div>
        </div>
        <div className='border cursor-pointer hover:rounded-lg rounded-b-lg shadow-sm P-5 bg-white w-[95%] transition-all hover:-translate-y-1 hover:scale-110 duration-300 shadow-white '>
          <div className='flex flex-row gap-10 px-2 lg:px-10 py-0'>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>17:15</p>
            </div>
            <div className='flex justify-center p-5'>
              <p className='text-black lg:text-[16px] text-[12px]'>Checkout Demo Call</p>
            </div>
          </div>
        </div>
        <p className='text-white mt-5 lg:text-start text-center lg:text-[16px] text-[12px]'>From your Google Calendar account: ashish.prajapati@lawinzo.com</p>
      </div>
    </div>
    <Footer />
  </>

  );
};

export default Home;
